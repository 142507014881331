<template>
    <div class="leadList">
        <v-row class="mb-15 pb-10">
            <v-col class="pb-0" align="center" cols="12">
                <v-btn text color="white" @click="showStudioPicker">
                    <v-icon>mdi-map-marker</v-icon>
                    Studio wechseln
                </v-btn>
            </v-col>
            <v-col cols="12">
                <v-text-field
                    v-model="search"
                    clearable
                    dark
                    filled
                    :rules="rules"
                    label="Lead Suche"
                    hint="Suche nach Name, E-Mail, Telefon, Werber in der ausgewählten Kategorie"
                ></v-text-field>
            </v-col>
            <v-col v-if="false" class="mb-0 pb-0" cols="12">
                <v-alert
                    class="mb-0"
                    @click="$router.push('/dashboard/leads/referral-deprecated')"
                    type="info"
                    v-if="deprecatedLeads || deprecatedLeadsContacted"
                >
                    Es gibt noch {{ deprecatedLeads.length }} neu angeworbene und
                    {{ deprecatedLeadsContacted.length }} kontaktierte "alte" Weiterempfehlungen (hier klicken, um die
                    alten Weiterempfehlungen zu bearbeiten)
                </v-alert>
            </v-col>
            <v-col cols="12" class="d-flex justify-center align-center mt-5 mb-10">
                <v-chip color="primary" class="mr-2" @click="walkinDialog = true"><span>Interessent +</span></v-chip>
                <v-chip-group v-model="selectedStatus" active-class="primary--text text--accent-4" mandatory>
                    <v-chip v-for="status in status" :key="status" :value="status">
                        <span v-if="status.toString() !== 'Geschlossen'">{{ status }}</span>
                        <span v-else>Nicht erreicht (geschlossen)</span>
                    </v-chip>
                </v-chip-group>
            </v-col>
            <v-col v-if="loading" cols="12" class="d-flex justify-center align-center" style="min-height: 200px">
                <v-progress-circular indeterminate color="primary" />
            </v-col>
            <v-col class="mx-auto" cols="12" v-if="!loading && leads.length === 0">
                <v-alert type="info" border="left" outlined>Keine Leads gefunden</v-alert>
            </v-col>
            <v-col
                v-for="lead of leads"
                :key="lead._id"
                :data-id="lead._id"
                v-intersect="{
                    handler: onIntersect,
                    options: {
                        threshold: 0.5,
                    },
                }"
            >
                <v-card
                    :class="{ 'd-flex': !lead.isVisible }"
                    class="mx-auto pb-5 fill-height"
                    dark
                    rounded-xl
                    min-height="500"
                    min-width="260"
                    max-width="344"
                >
                    <v-row v-if="lead.isVisible">
                        <v-col cols="12" class="py-2" v-if="lead.info">
                            <v-card-subtitle
                                class="text-center pb-0 mb-0 d-flex justify-center align-center"
                                v-if="lead.info.title"
                            >
                                {{ checkTitle(lead.info.title) }}
                            </v-card-subtitle>
                        </v-col>
                        <v-col cols="12" class="px-10">
                            <v-card-subtitle class="text-center pb-0 mb-0 d-flex justify-center align-center">
                                <v-icon class="mr-2">mdi-calendar-clock</v-icon>
                                {{ lead.createdAt | moment('DD.MM.YYYY HH:mm') }} Uhr
                            </v-card-subtitle>
                            <v-card-subtitle class="text-center mt-0 pt-0 d-flex justify-center align-center">
                                <v-icon class="mr-2">mdi-pencil</v-icon>
                                {{ lead.updatedAt | moment('DD.MM.YYYY HH:mm') }} Uhr
                            </v-card-subtitle>
                            <v-card-text v-if="lead.name" class="user-selectable text-center my-0 py-0">
                                {{ lead.name }}
                            </v-card-text>
                            <v-card-text v-if="lead.surname" class="user-selectable text-center my-0 py-0">
                                {{ lead.surname }}
                            </v-card-text>
                            <v-card-text v-if="lead.email" class="user-selectable text-center my-0 py-0">
                                {{ lead.email }}
                            </v-card-text>
                            <v-card-text v-if="lead.phone" class="user-selectable text-center my-0 py-0">
                                {{ lead.phone }}
                            </v-card-text>
                            <v-card-text v-if="lead.message" class="user-selectable text-center my-0 py-0">
                                {{ lead.message }}
                            </v-card-text>
                            <v-card-text
                                v-if="lead.referralMail || lead.roodyUser"
                                class="user-selectable text-center mb-0 pb-0"
                            >
                                Eingeladen von:
                            </v-card-text>
                            <v-card-text v-if="lead.referralMail" class="user-selectable text-center my-0 py-0">
                                {{ lead.referralMail }}
                            </v-card-text>
                            <v-card-text
                                v-if="lead.roodyUser && lead.roodyUser.vorname"
                                class="user-selectable text-center my-0 py-0"
                            >
                                {{ lead.roodyUser.vorname }}
                            </v-card-text>
                            <v-card-text
                                v-if="lead.roodyUser && lead.roodyUser.nachname"
                                class="user-selectable text-center my-0 py-0"
                            >
                                {{ lead.roodyUser.nachname }}
                            </v-card-text>
                            <v-textarea
                                class="pt-0 mt-5"
                                @blur="saveLead(lead)"
                                @click="setUneditedText(lead.notes)"
                                :loading="lead.loading"
                                v-model="lead.notes"
                                label="Anmerkungen"
                                outlined
                            ></v-textarea>
                            <v-card-text v-if="!status.includes(lead.status)">
                                ungültiger Status: {{ lead.status }}
                            </v-card-text>
                            <v-select
                                v-model="lead.status"
                                :items="statusFiltered"
                                label="Status"
                                persistent-hint
                                single-line
                                class="mb-0"
                            ></v-select>
                            <v-select
                                class="mb-2"
                                v-model="lead.studio"
                                hint="Wird im ausgewählten Studio gespeichert"
                                :items="studios"
                                item-text="name"
                                item-value="id"
                                label="Studio"
                                persistent-hint
                                single-line
                            ></v-select>
                        </v-col>
                    </v-row>

                    <v-card-actions v-if="lead.isVisible" class="d-flex justify-center align-center mb-10">
                        <v-btn @click="updateLead(lead)" color="primary" class="white--text">Speichern</v-btn>
                    </v-card-actions>
                    <v-progress-circular
                        color="primary"
                        class="ma-auto"
                        v-if="!lead.isVisible"
                        indeterminate
                    ></v-progress-circular>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog max-width="500px" v-model="walkinDialog">
            <v-card dark class="px-3 text-center">
                <v-card-title>Interessenten</v-card-title>
                <v-text-field label="Vorname" outlined v-model="walkin.surname"></v-text-field>
                <v-text-field label="Name" outlined v-model="walkin.name"></v-text-field>
                <v-text-field label="E-Mail" outlined v-model="walkin.email"></v-text-field>
                <v-text-field label="Telefon" outlined v-model="walkin.phone"></v-text-field>
                <v-select
                    outlined
                    label="Typ"
                    v-model="walkin.type"
                    :items="[
                        { text: 'Walk-In', value: '65000dc796e439bafefaf490' },
                        { text: 'Telefon', value: '6501b2707e1d78fc0506e23b' },
                        { text: 'E-Mail', value: '6501b2a27e1d78fc0506e29c' },
                        { text: 'Promotion / Messe', value: '65327e5379da10a6c15e8f79' },
                    ]"
                ></v-select>
                <v-text-field
                    label="Studio"
                    outlined
                    disabled
                    :value="'Wird im ausgewählten Studio hinterlegt'"
                ></v-text-field>
                <v-textarea label="Notiz" outlined v-model="walkin.note"></v-textarea>
                <v-btn class="mx-auto" fab small color="primary" @click="addWalkin"><v-icon>mdi-plus</v-icon></v-btn>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import api from '../../../api'
import $ from 'jquery'

export default {
    components: {},
    data() {
        return {
            uneditedText: '',
            timeout: null,
            search: '',
            deprecatedLeads: [],
            walkin: {},
            walkinDialog: false,
            deprecatedLeadsContacted: [],
            loading: true,
            error: false,
            leads: [],
            leadsBackup: [],
            studios: [],
            studio: null,
            fetchingLeads: false,
            status: [],
            selectedStatus: 'Wurde angeworben',
            rules: [
                (v) => !/\s/.test(v) || 'Leerzeichen sind nicht erlaubt', // Regel, um Leerzeichen zu verbieten
            ],
        }
    },
    async created() {
        this.loading = true
        this.init()

        let studio = null

        studio = await api.getUserStudio().catch((e) => {
            window.app.$root.$children[0].checkStudio()
        })
        studio = studio.studio
        this.studio = studio
        let studio_id = studio._id

        try {
            let studios = await api.fetchStudios()
            let filterCodes = ['ot', 'alibey']
            studios = studios
                .filter((e) => !filterCodes.includes(e.code))
                .map((e) => {
                    return { id: e._id, name: e.name }
                })

            this.studios = studios
        } catch (e) {}

        //  this.deprecatedLeads = await api.getInvites({ studio: studio_id, filter: 1 })
        // this.deprecatedLeadsContacted = await api.getInvites({ studio: studio_id, filter: 12 })
    },
    methods: {
        setUneditedText(note) {
            this.uneditedText = note
        },
        performSearch(query) {
            const searchQuery = query.toLowerCase()

            this.leads = this.leadsBackup.filter((lead) => {
                const fieldsToSearch = ['email', 'name', 'phone', 'surname', 'message', 'notes', 'referralMail']

                return fieldsToSearch.some((field) => lead[field]?.toLowerCase().includes(searchQuery))
            })
        },
        debounceSearch(query) {
            clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                this.performSearch(query)
            }, 500)
        },
        onIntersect(entries, observer) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const leadId = entry.target.dataset.id // Zugriff über dataset.id

                    const lead = this.leads.find((l) => l._id === leadId)
                    if (lead) {
                        lead.isVisible = true
                    }
                }
            })
        },
        showStudioPicker() {
            window.app.$root.$children[0].showStudioSelection = true
        },
        async addWalkin() {
            if (!this.walkin.type) {
                this.$toast.error('Bitte wähle einen Typen aus')
                return
            }
            await api.createWalkin(this.walkin)
            this.walkin = {}
            this.walkinDialog = false
            this.init()
            this.$toast.success('Walk-In wurde zur Lead Liste hinzugefügt')
        },
        checkTitle(title) {
            if (title.includes('Weiterempfehlung')) {
                return 'Weiterempfehlung'
            }
            return title
        },
        async saveLead(lead) {
            if (lead.notes !== this.uneditedText) {
                lead.loading = true
                let status = await api.updateLead(lead).catch((e) => this.$toast.error('Es gab einen Fehler'))
                if (status) {
                    this.$toast.success('Erfolgreich aktualisiert')
                }
                lead.loading = false
            }
        },
        async updateLead(lead) {
            if (lead.status !== this.selectedStatus || lead.studio !== this.studio._id) {
                let status = await api.updateLead(lead).catch((e) => this.$toast.error('Es gab einen Fehler'))
                if (status) {
                    this.leads = this.leads.filter((ld) => ld._id !== lead._id)
                    this.$toast.success('Erfolgreich aktualisiert')
                }
                console.log('update?')
            }
            // this.init(this.selectedStatus)
        },

        async init(status = 'Wurde angeworben') {
            this.loading = true
            this.leads = []

            let leadInformation = await api.fetchAllLeads(status).catch((e) => {
                this.loading = false
                this.error = true
                this.$toast.error('Es ist ein Fehler aufgetreten')
            })

            if (leadInformation && leadInformation.leads)
                this.leads = leadInformation.leads.map((lead) => ({ ...lead, isVisible: false }))
            if (leadInformation && leadInformation.leads && leadInformation.staten) this.status = leadInformation.staten
            this.leadsBackup = JSON.parse(JSON.stringify(this.leads))
            this.loading = false
            this.$emit('finishedloading')
        },
    },
    computed: {
        statusFiltered() {
            return this.status.map((s) => {
                return { text: s.toString() !== 'Geschlossen' ? s : 'Nicht erreicht (geschlossen)', value: s }
            })
        },
    },
    watch: {
        search(newValue) {
            this.debounceSearch(newValue)
        },
        selectedStatus: async function (newStatus, oldStatus) {
            if (newStatus !== oldStatus) {
                this.init(newStatus)
            }
        },
    },
}
</script>
<style lang="scss" scoped></style>
